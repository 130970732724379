class Video {
    constructor() {
        this.videoElement = document.querySelector('.js-video-modal');
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        const body = document.querySelector('body');

        if (this.videoElement) {
            body.addEventListener('click', this.handleClick.bind(this));
        }
    }

    handleClick(event) {
        const element = event.target;

        if (element.classList.contains('js-video-play')) {
            this.clearSources();

            const source = document.createElement('source');

            source.src = element.dataset.src;
            source.type = element.dataset.type;

            this.videoElement.appendChild(source);
            this.videoElement.play();
        } else if (element.nodeName !== 'VIDEO') {
            this.videoElement.pause();
        }
    }

    clearSources() {
        while (this.videoElement.hasChildNodes()) {
            this.videoElement.removeChild(this.videoElement.firstChild);
        }
    }
}

export default new Video();
