class Dropdown {
    constructor() {
        this.activeDropdown = null;
    }

    init() {
        this.bindClickEvents();
    }

    bindClickEvents() {
        document.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick(event) {
        const element = event.target;

        if (element.dataset.hasOwnProperty('dropdownOpen')) {
           this.open(element);
        } else if (this.activeDropdown && !this.activeDropdown.contains(element)) {
            this.closeAll(this.activeDropdown.dataset.dropdownGroup || false);
        }
    }

    open(element) {
        const target = document.querySelector(element.dataset.dropdownOpen);

        if (target) {
            if (target === this.activeDropdown) {
                this.closeAll(this.activeDropdown.dataset.dropdownGroup || false);
                return;
            }

            const group = target.dataset.dropdownGroup || false;

            this.closeAll(group);
            element.parentElement.classList.add('is-active');
            target.classList.add('is-active');

            this.activeDropdown = target;
        }
    }

    closeAll(group) {
        if (group) {
            const dropdownElements = document.querySelectorAll(`[data-dropdown-group="${group}"]`);

            for (let i = 0; i < dropdownElements.length; i++) {
                dropdownElements[i].classList.remove('is-active');
            }

            this.activeDropdown = null;
        }
    }
}

export default new Dropdown();
