import 'core-js/fn/array/from';
import 'core-js/fn/promise';

import flickity from 'flickity';

import Animation from 'nfx-design-system/js/modules/Animation';
import Modal from './modules/Modal';
import Analytics from 'nfx-design-system/js/modules/Analytics';

import Tabs from './modules/Tabs';
import Accordion from './modules/Accordion';
import SmoothScroll from './modules/SmoothScroll';
import MobileNavigation from './modules/MobileNavigation';
import Dropdown from './modules/Dropdown';
import Video from './modules/Video';
import Redirect from './modules/Redirect';

Tabs.init();
Accordion.init();
SmoothScroll.init();
MobileNavigation.init();
Dropdown.init();
Modal.init();
Animation.init();
Video.init();
Redirect.init();

const analytics = new Analytics(function (event) {
    window.dataLayer = (window.dataLayer || []);
    window.dataLayer.push(event);
});

analytics.addCustomEvent('#account-teaser .teaser', '.button.is-primary', 'Call to Action', '', '.interest', true, true);
analytics.addCustomEvent('body', '[data-modal-open="#modal-contact"]', 'Call to Action', 'Kontaktaufnahme', 'Kontaktformular');
analytics.addCustomEvent('body', '.link.icon-banking', 'Button', 'E-Banking-Login');
analytics.init();


const ua = window.navigator.userAgent;
const trident = ua.indexOf('Trident/');

if (trident > 0) {
    document.querySelector('body').classList.add('is-ie');
}

let flickityOptions = {
    wrapAround: true,
    draggable: false,
    selectedAttraction: 0.01,
    friction: 0.15
};

if (window.innerWidth > 1024) {
    flickityOptions.autoPlay = 4000;
}

if (document.querySelector('.js-slider')) {
    new flickity('.js-slider', flickityOptions);
}

document.addEventListener('DOMContentLoaded', function() {
   document.querySelector('body').classList.add('slider-ready');
});
