class Redirect {
    constructor() {
        this.targetAnchor = document.querySelector('.js-redirect-url');
    }

    init() {
        const body = document.querySelector('body');

        body.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick(event) {
        const element = event.target;
        let counter = 5;

        if (document.querySelector('.js-redirect-count')) {
            document.querySelector('.js-redirect-count').textContent = counter;
        }

        if (element.classList.contains('js-redirect')) {
            event.preventDefault();

            const href = element.getAttribute('href');

            this.targetAnchor.href = href;

            let counterInterval = setInterval(function() {
                document.querySelector('.js-redirect-count').textContent = --counter;

                if (counter <= 0) {
                    clearInterval(counterInterval);
                    document.querySelector('.modal.is-active').classList.remove('is-active');
                    document.location.href = href;
                }
            }, 1000);
        }
    }
}

export default new Redirect();
