const Tabs = {
    init() {
        const tabContainer = document.querySelector('[data-tabs]');
        const tabs = document.querySelectorAll(('[data-tab]'));
        const tabPanels = document.querySelectorAll('[data-tab-content]');

        if (!tabContainer) {
            return;
        }

        tabContainer.addEventListener('click', function (event) {
            const tabLink = event.target;
            const tab = tabLink.parentNode;

            if (tab.dataset.hasOwnProperty('tab')) {
                for (let i = 0; i < tabPanels.length; i++) {
                    tabPanels[i].classList.remove('is-active');
                }

                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].classList.remove('is-active');
                }

                document.querySelector(`#${tab.dataset.target}`).classList.add('is-active');
                tab.classList.add('is-active');
            }
        });
    }
};

export default Tabs;
