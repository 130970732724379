const MobileNavigation = {
  init() {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('#' + burger.dataset.target);

    if (!burger) {
      return;
    }

    burger.addEventListener('click', function () {
      burger.classList.toggle('is-active');
      nav.classList.toggle('is-active');
    });
  }
};

export default MobileNavigation;



