const Accordion = {
    init() {
        const accordionContainer = document.querySelectorAll('[data-accordion]');

        if (!accordionContainer) {
            return;
        }

        for (let container = 0; container < accordionContainer.length; container++) {
            accordionContainer[container].titles = accordionContainer[container].querySelectorAll('[data-accordion-title]');

            accordionContainer[container].addEventListener('click', function (event) {
                const element = event.target;

                if (!element.dataset.hasOwnProperty('accordionTitle')) {
                    return;
                }

                for (let title = 0; title < accordionContainer[container].titles.length; title++) {
                    if (accordionContainer[container].titles[title] !== element) {
                        accordionContainer[container].titles[title].classList.remove('is-active');
                    }
                }

                element.classList.toggle('is-active');
            });
        }
    }
};

export default Accordion;
