import smoothscroll from 'smoothscroll-polyfill';

const SmoothScroll = {
    init() {
        smoothscroll.polyfill();

        const headerHeight = document.querySelector('header').getBoundingClientRect().height;

        if (document.location.hash.length > 0) {
            const target = document.querySelector(document.location.hash.replace('scrollto-', ''));

            if (target) {
                let position;
                const clientRect = target.getBoundingClientRect();

                if (target.classList.contains('is-visible')) {
                    position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                } else {
                    position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight - clientRect.height;
                }

                window.scrollTo({
                    top: position,
                    behavior: 'smooth'
                });
            }
        }

        document.addEventListener('click', function (event) {
            const element = event.target;

            if (element.nodeName === 'A' && element.hasAttribute('href') && element.getAttribute('href').indexOf('#') === 0) {
                event.preventDefault();

                const target = document.querySelector(event.target.getAttribute('href'));

                if (target) {
                    let position;
                    const clientRect = target.getBoundingClientRect();

                    if (target.classList.contains('is-visible')) {
                        position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                    } else {
                        position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight - clientRect.height;
                    }

                    window.scrollTo({
                        top: position,
                        behavior: 'smooth'
                    });
                }
            }
        });
    }
};

export default SmoothScroll;
